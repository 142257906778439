import React from 'react'

import {
  Button,
  Inline,
  List,
  ListItem,
  Paragraph,
  XIcon
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { LinkMock } from '../../components/LinkMock'
import { Section } from '../../components/Section'
import { StatusMessage } from '../../components/StatusMessage'
import ButtonHTMLExample from '../../examples/html/ButtonHTMLExample'
import IconHTMLExample from '../../examples/html/IconHTMLExample'

const onButtonClick = () => alert('Click!')

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Button"
    components={[{ component: Button, restElement: true }]}
    status={[
      { type: 'accessible', version: '14.4.0' },
      { type: 'breaking_changes', version: '13.0.0' }
    ]}
  >
    <Section>
      <StatusMessage
        status={{ type: 'breaking_changes', version: '13.0.0' }}
        messageInfo={
          <>
            <Paragraph>
              Saavutettavuuden vuoksi painikkeesta on poistettu maksimipituus,
              joka katkaisi pitkän tekstin.
            </Paragraph>
            <Paragraph noMargin>
              Muutoksen voi ohittaa käyttämällä <Code>hasMaxWidth</Code>{' '}
              -proppia, mutta se ei ole suositeltavaa. Keskustele UX:n ja Style
              Guide -tiimin kanssa ennen propin käyttöön ottamista.
            </Paragraph>
          </>
        }
      />
    </Section>

    <Section>
      <Paragraph>
        Yleinen periaate on, että <Code>Button</Code>-komponenttia käytetään,
        kun kyseessä on toiminto, <Code>Link</Code>ia puolestaan käytetään
        siirryttäessä toiselle sivulle.
      </Paragraph>
      <Paragraph>
        Painikkeen tekstissä pyritään käyttämään verbiä, joka kertoo käyttäjälle
        suoraan ja selväsanaisesti minkä toiminnon painikkeen painallus
        suorittaa, esimerkiksi &quot;Tallenna&quot;, &quot;Lisää uusi&quot;,
        &quot;Seuraava&quot;.
      </Paragraph>
      <Paragraph>
        Tarkemmin painikkeiden käytöstä Toimintopainikkeet-patternissa ja
        yksittäisten Vakioitujen toimintopainikkeiden patterneissa.
      </Paragraph>
      <Paragraph noMargin>
        Jos sijoitat useamman painikkeen vierekkäin, yhdistä ne{' '}
        <Code>Buttons</Code>-komponentilla. Painikkeet asettuvat pienillä
        näytöillä päällekkäin ja isoilla näytöillä vierekkäin pitäen etäisyyden
        toisiinsa.
      </Paragraph>
    </Section>
    <Section title="Primary">
      <Paragraph>
        Painikkeen oletustila, jolla korostetaan käyttäjän kannalta oleellisia
        toimintoja.
      </Paragraph>
      <Playground>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <Button onClick={onButtonClick}>Teksti</Button>
          <Button
            iconLeft={<XIcon />}
            onClick={onButtonClick}
          >
            Teksti
          </Button>
          <Button
            iconRight={<XIcon />}
            onClick={onButtonClick}
          >
            Teksti
          </Button>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample>Teksti</ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample iconLeft={<IconHTMLExample name="XIcon" />}>
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample iconRight={<IconHTMLExample name="XIcon" />}>
              Teksti
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Secondary">
      <Paragraph>
        Korostettu painike, jolla osoitetaan pakollisia ja peruuttamattomia
        toimintoja. (esim. Julkaise ja Lähetä).
      </Paragraph>
      <Playground>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <Button
            color="secondary"
            onClick={onButtonClick}
          >
            Teksti
          </Button>
          <Button
            color="secondary"
            iconLeft={<XIcon />}
            onClick={onButtonClick}
          >
            Teksti
          </Button>
          <Button
            color="secondary"
            iconRight={<XIcon />}
            onClick={onButtonClick}
          >
            Teksti
          </Button>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample color="secondary">Teksti</ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              color="secondary"
              iconLeft={<IconHTMLExample name="XIcon" />}
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              color="secondary"
              iconRight={<IconHTMLExample name="XIcon" />}
            >
              Teksti
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Outline">
      <Paragraph>
        Huomioarvoltaan vähäisempi painike, jolla tarjotaan käyttäjän kannalta
        vähemmän tärkeitä toimintoja (esim. avataan modaali uudestaan ja
        muokataan jo kertaalleen muokattua sisältöä).
      </Paragraph>
      <Playground>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <Button variant="outline">Toissijainen toiminto</Button>
          <Button
            disabled
            variant="outline"
          >
            Disabloitu
          </Button>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample variant="outline">
              Toissijainen toiminto
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              disabled
              variant="outline"
            >
              Disabloitu
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
      <Playground isDark>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <Button
            color="white"
            variant="outline"
          >
            Toiminto
          </Button>
          <Button
            color="white"
            disabled
            variant="outline"
          >
            Disabloitu
          </Button>
        </Inline>
      </Playground>
      <Playground
        isDark
        format="html"
      >
        <div className="flex flex-wrap mt-n-md">
          <div className="mr-md mt-md">
            <ButtonHTMLExample
              color="white"
              variant="outline"
            >
              Toiminto
            </ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              color="white"
              disabled
              variant="outline"
            >
              Disabloitu
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Danger">
      <Paragraph>
        Painike, joka varoittaa käyttäjää tietoa poistavasta toiminnosta (esim.
        julkaistun lomakkeen poistaminen kokonaan). Danger-painikkeen kanssa
        käytetään aina varmistus-modaalia.
      </Paragraph>
      <Playground>
        <Button
          color="danger"
          onClick={onButtonClick}
        >
          Poista
        </Button>
      </Playground>
      <Playground format="html">
        <ButtonHTMLExample color="danger">Poista</ButtonHTMLExample>
      </Playground>
    </Section>
    <Section title="Disabled">
      <Paragraph>
        <Code>disabled</Code>-proppi estää painikkeen painamisen.
        Lähtökohtaisesti disabled-tilaa tulisi välttää, koska sen kontrasti on
        heikohko, ja saattaa jäädä helpommin huomaamatta käyttäjältä. Jos
        painikkeesta tapahtuva toiminto ei ole mahdollinen, painike pidetään
        ensisijaisesti aktiivisena ja annetaan käyttäjälle palaute painikkeen
        painamisesta. Palaute kuvaa, miksi toiminto ei onnistu ja auttaa
        käyttäjää korjaamaan tilanteen.
      </Paragraph>
      <Playground>
        <Button
          disabled
          onClick={onButtonClick}
        >
          Teksti
        </Button>
      </Playground>
      <Playground format="html">
        <ButtonHTMLExample disabled>Teksti</ButtonHTMLExample>
      </Playground>
    </Section>
    <Section title="Plain">
      <Paragraph>
        Hienovarainen painike, jolla tarjotaan näkymän yksittäisiin osiin
        kohdistuvia tai prosessin etenemisen keskeyttäviä toimintoja (esim.
        lomakkeen Keskeytä ja Jatka myöhemmin -toiminnot sekä yksittäisen tiedon
        Muokkaa-toiminto).
      </Paragraph>
      <Playground>
        <Inline
          gap="xxl"
          wrap="wrap"
        >
          <Button
            icon={<XIcon />}
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
          <Button
            iconRight={<XIcon />}
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
          <Button
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
          <Button
            color="danger"
            iconLeft={<XIcon />}
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
          <Button
            disabled
            iconRight={<XIcon />}
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
        </Inline>
      </Playground>
      <Playground isDark>
        <Inline
          gap="xxl"
          wrap="wrap"
        >
          <Button
            color="white"
            iconRight={<XIcon />}
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
          <Button
            color="white"
            disabled
            iconRight={<XIcon />}
            onClick={onButtonClick}
            variant="plain"
          >
            Teksti
          </Button>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-xxl">
          <div className="mr-xxl mt-xxl">
            <ButtonHTMLExample
              variant="plain"
              icon={<XIcon />}
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mr-xxl mt-xxl">
            <ButtonHTMLExample
              variant="plain"
              iconRight={<XIcon />}
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mr-xxl mt-xxl">
            <ButtonHTMLExample variant="plain">Teksti</ButtonHTMLExample>
          </div>
          <div className="mr-xxl mt-xxl">
            <ButtonHTMLExample
              color="danger"
              variant="plain"
              iconLeft={<XIcon />}
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mt-xxl">
            <ButtonHTMLExample
              disabled
              variant="plain"
              iconRight={<XIcon />}
            >
              Teksti
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Ikonilla">
      <Paragraph>
        Jos <Code>icon</Code>-proppi on määritelty, niin label piilotetaan ja
        ikoni näytetään sen tilalla. Label luetaan silti ruudunlukijaa
        käyttäessä.
      </Paragraph>
      <Playground>
        <Button
          icon={<XIcon />}
          onClick={onButtonClick}
        >
          Teksti
        </Button>
      </Playground>
    </Section>
    <Section title="Loading">
      <Paragraph>
        <Code>loading</Code>-proppi estää painikkeen painamisen ja näyttää
        painikkeessa spinnerin. Käytetään, jos toiminnosta tapahtuu jokin
        odottamista vaativa tapahtuma.
      </Paragraph>
      <Playground>
        <Button
          loading
          onClick={onButtonClick}
        >
          Teksti
        </Button>
      </Playground>
    </Section>
    <Section title="Pieni painike">
      <Paragraph>
        <Code>size='xs'</Code> muuttaa painikkeen kokoa pienemmäksi. Pieniä
        painikkeita käytetään lähinnä taulukoissa säästämään tilaa.
      </Paragraph>
      <Playground>
        <Inline
          gap="md"
          wrap="wrap"
        >
          <Button size="xs">Teksti</Button>
          <Button
            size="xs"
            variant="outline"
          >
            Teksti
          </Button>
          <Button
            iconLeft={<XIcon />}
            size="xs"
          >
            Teksti
          </Button>
          <Button
            iconRight={<XIcon />}
            size="xs"
            variant="outline"
          >
            Teksti
          </Button>
          <Button
            disabled
            size="xs"
          >
            Teksti
          </Button>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample size="xs">Teksti</ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              size="xs"
              variant="outline"
            >
              Teksti
            </ButtonHTMLExample>
          </div>
          <div className="mt-md mr-md">
            <ButtonHTMLExample
              disabled
              size="xs"
            >
              Teksti
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Painike linkkinä">
      <Paragraph>
        <Code>Button</Code>-komponentille voi antaa esim. React Router{' '}
        <Code>Link</Code>-komponentin <Code>as</Code>-propsina, jolloin sitä voi
        käyttää sovelluksessa linkkinä.
      </Paragraph>
      <Playground>
        <Inline gap="md">
          <Button
            as={LinkMock}
            to="#"
          >
            Linkki
          </Button>
          <Button
            as={LinkMock}
            disabled
            to="#"
          >
            Linkki
          </Button>
        </Inline>
      </Playground>
      <Playground format="html">
        <div className="flex flex-wrap mt-n-md">
          <div className="mt-md mr-md">
            <ButtonHTMLExample as={LinkMock}>Linkki</ButtonHTMLExample>
          </div>
          <div className="mt-md">
            <ButtonHTMLExample
              as={LinkMock}
              disabled
            >
              Linkki
            </ButtonHTMLExample>
          </div>
        </div>
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph noMargin>Tarkistuslista:</Paragraph>
      <List variant="unordered">
        <ListItem>Vältä pitkiä tekstejä.</ListItem>
        <ListItem>
          Varmista, että painikkeen teksti kuvaa toiminnon tarkoitusta.
        </ListItem>
        <ListItem>
          Varmista, että painikkeen kosketuskohteen koko on riittävän suuri.
          MIT:n mukaan etusormen halkaisija on 16-20 mm, ja sopiva kosketusalue
          on 45-57 pikseliä. Apple suosittelee 44*44 pikseliä ja Android 48*48
          pikseliä.
        </ListItem>
      </List>
      <Paragraph>
        Painikkeen tekstin tulee näkyä kokonaan myös kapeilla ruuduilla ja
        käyttäjän omalla custom-tyylitiedostolla, jossa voi olla{' '}
        <Code>letter-spacing</Code> tai <Code>word-spacing</Code> -sääntöjä.
      </Paragraph>
      <Paragraph>
        Ikonipainikkeita tulee käyttää harkiten, koska ei voida pitää
        itsestäänselvänä, että käyttäjä ymmärtää ikonin merkityksen ilman
        kuvaavaa tekstiä.
      </Paragraph>
      <Paragraph>
        Ikonipainikkeita käyttäessä on erityisen tärkeää huolehtia, että
        painikkeella on teksti, joka voidaan lukea ruudunlukijalla.
        Tyypillisesti teksti annetaan <Code>Button</Code>in childreninä. Jos
        childreninä on monimutkainen <Code>node</Code>, voidaan määritellä
        yksikäsitteinen teksti <Code>ariaLabel</Code>illa. Tarvittaessa viittaus
        sopivaan tekstiin voidaan lisätä <Code>ariaLabelledby</Code>llä.
      </Paragraph>
      <Paragraph>
        Joskus painikkeen teksti on ymmärrettävä kontekstissa nähtynä, mutta
        tabilla selatessa konteksti hukkuu (esim. &quot;Lisää uusi&quot;).
        Tällöin tarkempi teksti voidaan lisätä <Code>ariaLabel</Code>ina (esim.
        &quot;Lisää uusi + mikä?&quot;). <Code>ariaLabel</Code> yliajaa
        painikkeessa näkyvän tekstin. Alla olevassa esimerkissä ruudunlukija
        lukee &quot;Lisää uusi asia x painike&quot;, vaikka painikkeen näkyvä
        teksti on &quot;Lisää uusi&quot;.
      </Paragraph>
      <Playground>
        <Button
          onClick={onButtonClick}
          ariaLabel="Lisää uusi asia x"
        >
          Lisää uusi
        </Button>
      </Playground>
    </Section>
  </Content>
)

export default Page
